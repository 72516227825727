<template>
  <div class="position-detail-box">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="position-detail-con-box" v-if="detailData">
      <div class="list">
        <div class="list-base">
          <span>{{ detailData.name }}</span>
          <div @click="edit">
            <img src="../../assets/positionMgt/edit.png" alt="" />
            <em>编辑</em>
          </div>
        </div>
        <div class="list-salary">
          <i>{{ detailData.salaryRangeName }}</i>
        </div>
        <div class="list-label">
          <span>{{ detailData.city }}</span>
          <span>{{ detailData.workExperienceName }}</span>
          <span>{{ detailData.qualificationsName }}</span>
          <span>{{ detailData.numbers }}人</span>
        </div>
        <div class="list-view">
          <span>
            浏览
            <i>{{ detailData.browseNum }}次</i>
          </span>
          <em></em>
          <span>
            投递
            <i>{{ detailData.deliveryCount }}次</i>
          </span>
        </div>
        <div class="list-status">
          <span>创建于{{ detailData.createdTime | timeFilter }}</span>
          <span>
            <img
              :src="require(`../../assets/positionMgt/${detailData.state}.png`)"
              alt
            />
            <i :class="{ waiting: detailData.state === 'APPROVE_NORMAL', error: detailData.state === 'APPROVE_NOT_PASS' }">{{
              detailData.stateDesc
            }}</i>
          </span>
        </div>
        <div class="reason" v-if="detailData.state === 'APPROVE_NOT_PASS'">
          未通过原因：{{ detailData.approveReason || '-'}}
        </div>
      </div>
      <div class="must-require-box">
        <h4>职位详情</h4>
        <div class="must-require">
          <h5>硬性要求</h5>
          <p v-html="detailData.hardCondition"></p>
        </div>
      </div>
      <div class="position-detail-list-box">
        <p>职位描述</p>
        <div class="position-detail-list" v-html="detailData.desc"></div>
      </div>
      <div class="position-detail-list-box">
        <p>任职要求</p>
        <div
          class="position-detail-list"
          v-html="detailData.requirements"
        ></div>
      </div>
      <div class="go-release-box">
        <van-button
          v-if="detailData.state === 'APPROVE_NORMAL'"
          type="info"
          plain
          round
          block
          @click="takeDown"
          >下架职位</van-button
        >
        <van-button
          v-if="
            detailData.state === 'WAITING' ||
            detailData.state === 'APPROVE_EXPIRE' ||
            detailData.state === 'APPROVE_DOWN'
          "
          type="info"
          round
          block
          @click="takeUp"
          >{{
            detailData.state === 'WAITING' ? '上架' : '再次上架'
          }}</van-button
        >
        <van-button
          v-if="detailData.state === 'APPROVE_NOT_PASS'"
          type="info"
          round
          block
          @click="auditAgain"
          >再次审核</van-button
        >
      </div>
      <van-calendar
        v-model="dateCheck"
        title="选择上架时间范围"
        color="#1989fa"
        :max-range="30"
        type="range"
        @confirm="onConfirm"
      />
    </div>
    <img
      v-if="detailData && detailData.state === 'APPROVE_NORMAL'"
      class="share-box"
      src="../../assets/positionMgt/share.png"
      @click="getDefualtShare"
    />
    <van-popup
      v-model="showShare"
      closeable
      close-icon="close"
      position="bottom"
      round
      :style="{ height: '60%' }"
      @close="closeShare"
    >
      <div class="share-con">
        <h5>选择分享卡片类型</h5>
        <i>文案图</i>
        <div class="img-box">
          <div
            v-for="(img, i) in defualtImgs"
            :key="i"
            class="img"
            @click="checkIdx = i"
            :style="`background-image:url(${img})`"
          >
            <span>
              <img
                v-if="checkIdx !== i"
                src="../../assets/positionMgt/share_check.png"
                alt=""
              />
              <img
                v-else
                src="../../assets/positionMgt/share_checked.png"
                alt=""
              />
            </span>
          </div>
        </div>
        <p>分享至微信小程序或QQ小程序</p>
        <div class="share-type">
          <ul>
            <dt>
              <img
                src="../../assets/positionMgt/share_wx.png"
                alt=""
                @click="wxShare"
              />
            </dt>
            <dd>微信小程序</dd>
          </ul>
          <ul>
            <dt>
              <img
                src="../../assets/positionMgt/share_qq.png"
                alt=""
                @click="qqShare"
              />
            </dt>
            <dd>QQ小程序</dd>
          </ul>
        </div>
      </div>
    </van-popup>
    <van-image-preview v-model="picShow" :images="images" @change="onChange">
    </van-image-preview>
  </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'positionDetail',
    data() {
      return {
        detailData: null,
        value1: 0,
        title: '职位详情',
        dateCheck: false,
        showShare: false,
        positionId: '',
        date: '',
        checkIdx: null, //分享图片选中
        picShow: false,
        images: [],
        defualtImgs: [],
      };
    },
    filters: {
      timeFilter(data) {
        return moment(new Date(data)).format('YYYY.MM.DD h:mm');
      },
    },
    mounted() {
      let { query } = this.$route;
      if (query.positionId) {
        this.positionId = query.positionId;
        this.getPositionDetail(query.positionId);
      }
      if (localStorage.getItem('accessToken')) {
        this.token = localStorage.getItem('accessToken');
      }
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      getDefualtShare() {
        this.$http
          .get('/selfhelpOfficialAccounts/systemConfig/shareImages/')
          .then((res) => {
            if (res.code === 200) {
              this.defualtImgs = res.data.positionShareMinImageList;
              this.showShare = true;
            }
          });
      },
      getPositionDetail(id) {
        this.$http
          .get('/selfhelpOfficialAccounts/positionSelfhelp/' + id)
          .then((res) => {
            if (res.code === 200) {
              this.detailData = res.data;
            }
          });
      },
      edit() {
        this.$router.push({
          path: '/positionMgt/positionAddOrEdit',
          query: { id: this.positionId },
        });
      },
      // 下架
      takeDown() {
        this.$dialog
          .confirm({
            message:
              '该操作将下架职位,您确认要下架该职位吗?',
          })
          .then(() => {
            this.$http
              .put(
                `/selfhelpOfficialAccounts/positionSelfhelp/updateState/${this.positionId}/6`
              )
              .then((res) => {
                if (res.code === 200) {
                  this.$toast('下架成功');
                  this.getPositionDetail(this.positionId);
                } else {
                  this.$toast(res.message || '下架失败');
                }
              });
          });
      },
      // 上架
      takeUp() {
        this.$dialog
          .confirm({
            message:
              '该操作将上架职位,每次上架时长最多30天,您确认要上架该职位吗?',
          })
          .then(() => {
            this.dateCheck = true;
          });
      },
      // 再次审核
      auditAgain() {
        this.$dialog
          .confirm({
            message: '您将再次发送职位审核请求?',
          })
          .then(() => {
            this.$http
              .put(
                `/selfhelpOfficialAccounts/positionSelfhelp/updateState/${this.positionId}/2`
              )
              .then((res) => {
                if (res.code === 200) {
                  this.$toast('发送成功');
                  this.getPositionDetail(this.positionId);
                } else {
                  this.$toast(res.message || '发送失败');
                }
              });
          });
      },
      beforeClose(action, done) {
        if (action === 'confirm') {
          done();
        } else {
          done();
        }
      },
      addPosition() {
        if (this.isEmail) {
          if (this.isLegalize) {
            this.$router.push('/positionMgt/positionAddOrEdit');
          } else {
            this.$router.push('/noLegalize');
          }
        } else {
          this.$dialog
            .confirm({
              title: '提示',
              message: '您还未验证邮箱，请立即前往验证',
            })
            .then(() => {
              // on close
            });
        }
      },
      formatDate(date) {
        return `${date.getMonth() + 1}/${date.getDate()}`;
      },
      onConfirm(date) {
        const [start, end] = date;
        this.dateCheck = false;
        // this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
        this.$http
          .put(
            `/selfhelpOfficialAccounts/positionSelfhelp/updateState/${this.positionId}/5`,
            {
              start: moment(new Date(start)).format('YYYY-MM-DD'),
              end: moment(new Date(end)).format('YYYY-MM-DD'),
            }
          )
          .then((res) => {
            if (res.code === 200) {
              this.$toast('上架成功');
              this.getPositionDetail(this.positionId);
            } else {
              this.$toast(res.message || '上架失败');
            }
          });
      },
      getShareImg(type) {
        this.$http
          .get('/selfhelpOfficialAccounts/positionSelfhelp/sharedImg', {
            positionId: this.positionId,
            page: 'pages/jobDetail/index',
            scence: this.positionId,
            source: type,
            token: this.token,
            url: this.defualtImgs[this.checkIdx],
          })
          .then((res) => {
            if (res.code === 200) {
              this.picShow = true;
              this.images = res.data;
            }
          });
      },
      wxShare() {
        if (this.checkIdx === null) {
          this.$toast('未选择分享图案！');
          return;
        }

        this.getShareImg('weChat');
      },
      qqShare() {
        if (this.checkIdx === null) {
          this.$toast('未选择分享图案！');
          return;
        }
        this.getShareImg('qq');
      },
      closeShare() {
        this.showShare = false;
      },
      onChange() {},
    },
  };
</script>

<style lang="scss" scoped>
  .position-detail-box {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    .position-detail-con-box {
      i {
        font-style: normal;
      }
      flex: 1;
      overflow-y: auto;
      .list {
        padding: 15px;
        background: #ffffff;
        border-radius: 4px;
        .list-base {
          display: flex;
          justify-content: space-between;
          span {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }
          > div {
            display: flex;
            align-items: center;
            img {
              height: 11px;
              width: 11px;
              margin-right: 5px;
            }
            em {
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              font-style: normal;
            }
          }
        }
        .list-salary {
          margin-top: 10px;
          i {
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            font-size: 18px;
            color: #f44336;
          }
        }
        .list-label {
          margin: 10px 0;
          span {
            background: #f7f7f7;
            border-radius: 2px;
            padding: 0 4px;
            margin-right: 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .list-view {
          padding-bottom: 15px;
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            i {
              color: #333333;
            }
          }
          em {
            margin: 0 10px;
            height: 6px;
            width: 1px;
            display: inline-block;
            background: #cccccc;
          }
        }
        .list-status {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            display: flex;
            align-items: center;
            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
          }
          .waiting {
            color: #206cfe;
          }
          .error{
            color: #f44336;
          }
        }
        .reason {
          margin-top: 10px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #f44336;
        }
      }
      .must-require-box {
        background: #fff;
        width: 100%;
        margin-top: 10px;
        padding: 15px 15px 0 15px;
        overflow: hidden;
        .must-require {
          border-radius: 4px;
          padding: 15px;
          margin: 20px 0;
          background: #f8faff;
          h4 {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            font-weight: normal;
            margin: 15px 0 20px 0;
          }
          h5 {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #367bff;
            font-weight: normal;
            margin-bottom: 10px;
          }
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #367bff;
          }
        }
      }
      .position-detail-list-box {
        padding: 0 15px;
        background: #fff;
        p {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-bottom: 10px;
        }
        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          padding-bottom: 20px;
        }
      }
      .go-release-box {
        background: #fff;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 20px 34px;
        .van-button {
          margin: 0 7.5px;
        }
      }
    }
    .share-box {
      position: fixed;
      right: 15px;
      top: 45%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      box-shadow: 0px 7px 12px 0px rgba(32, 108, 254, 0.2);
    }
    .share-con {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      align-items: center;
      padding-top: 20px;
      height: 100%;
      overflow-y: auto;
      h5 {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin-bottom: 10px;
        font-weight: normal;
      }
      i {
        font-style: normal;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 20px;
      }
      .img-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 33px;
        width: 100%;
        .img {
          width: 105px;
          height: 170px;
          margin: 0 8px;
          background-color: #cccccc;
          background-repeat: no-repeat;
          position: relative;
          background-size: 100% 100%;
          border-radius: 10px;
          span {
            position: absolute;
            right: 3px;
            top: 3px;
            img {
              width: 11px;
              height: 11px;
            }
          }
        }
      }
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 18px;
      }
      .share-type {
        display: flex;
        padding: 0 98px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 50px;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          dt {
            margin-bottom: 8px;
            img {
              height: 42px;
              width: 42px;
            }
          }
          dd {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }

  ::v-deep .van-dropdown-menu__bar {
    height: 44px;
    box-shadow: none;
  }
</style>
